export const FILTER_ADVISORIES = 'FILTER_ADVISORIES'
export const GET_ADVISORIES = 'GET_ADVISORIES'
export const DELETE_ADVISORY = 'DELETE_ADVISORY'
export const ADD_ADVISORY = 'ADD_ADVISORY'
export const UPDATE_ADVISORY = 'UPDATE_ADVISORY'
export const SAVING_ADVISORY = 'SAVING_ADVISORY'
export const SAVING_ADVISORY_CANCEL = 'SAVING_ADVISORY_CANCEL'
export const ADVISORIES_LOADING = 'ADVISORIES_LOADING'
export const FILTER_DISTRICTS = 'FILTER_DISTRICTS'
export const GET_DISTRICTS = 'GET_DISTRICTS'
export const DELETE_DISTRICT = 'DELETE_DISTRICT'
export const ADD_DISTRICT = 'ADD_DISTRICT'
export const UPDATE_DISTRICT = 'UPDATE_DISTRICT'
export const SAVING_DISTRICT = 'SAVING_DISTRICT'
export const SAVING_DISTRICT_CANCEL = 'SAVING_DISTRICT_CANCEL'
export const DISTRICTS_LOADING = 'DISTRICTS_LOADING'
export const FILTER_ORGANIZATIONS = 'FILTER_ORGANIZATIONS'
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const SAVING_ORGANIZATION = 'SAVING_ORGANIZATION'
export const SAVING_ORGANIZATION_CANCEL = 'SAVING_ORGANIZATION_CANCEL'
export const ORGANIZATIONS_LOADING = 'ORGANIZATIONS_LOADING'
export const FILTER_MEMBERSHIPS = 'FILTER_MEMBERSHIPS'
export const GET_MEMBERSHIPS = 'GET_MEMBERSHIPS'
export const INIT_MEMBERSHIPS = 'INIT_MEMBERSHIPS'
export const DELETE_MEMBERSHIP = 'DELETE_MEMBERSHIP'
export const ADD_MEMBERSHIP = 'ADD_MEMBERSHIP'
export const UPDATE_MEMBERSHIP = 'UPDATE_MEMBERSHIP'
export const SAVING_MEMBERSHIP = 'SAVING_MEMBERSHIP'
export const SAVING_MEMBERSHIP_CANCEL = 'SAVING_MEMBERSHIP_CANCEL'
export const MEMBERSHIPS_LOADING = 'MEMBERSHIPS_LOADING'
export const FILTER_PLACES = 'FILTER_PLACES'
export const GET_PLACES = 'GET_PLACES'
export const DELETE_PLACE = 'DELETE_PLACE'
export const ADD_PLACE = 'ADD_PLACE'
export const UPDATE_PLACE = 'UPDATE_PLACE'
export const SAVING_PLACE = 'SAVING_PLACE'
export const SAVING_PLACE_CANCEL = 'SAVING_PLACE_CANCEL'
export const PLACES_LOADING = 'PLACES_LOADING'
export const GEO_CLEAR = 'GEO_CLEAR'
export const GEO_LOADED = 'GEO_LOADED'
export const GEO_LOADING = 'GEO_LOADING'
export const GEO_UNWATCH = 'GEO_UNWATCH'
export const GEO_WATCH = 'GEO_WATCH'
export const FILTER_THEMES = 'FILTER_THEMES'
export const GET_THEMES = 'GET_THEMES'
export const DELETE_THEME = 'DELETE_THEME'
export const ADD_THEME = 'ADD_THEME'
export const UPDATE_THEME = 'UPDATE_THEME'
export const SAVING_THEME = 'SAVING_THEME'
export const SAVING_THEME_CANCEL = 'SAVING_THEME_CANCEL'
export const THEMES_LOADING = 'THEMES_LOADING'
export const FILTER_USERS = 'FILTER_USERS'
export const GET_USERS = 'GET_USERS'
export const DELETE_USER = 'DELETE_USER'
export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const SAVING_USER = 'SAVING_USER'
export const SAVING_USER_CANCEL = 'SAVING_USER_CANCEL'
export const USERS_LOADING = 'USERS_LOADING'
export const FILTER_VISITS = 'FILTER_VISITS'
export const GET_VISITS = 'GET_VISITS';
export const INIT_VISITS = 'INIT_VISITS'
export const CANCEL_VISIT = 'CANCEL_VISIT'
export const DELETE_VISIT = 'DELETE_VISIT';
export const ADD_VISIT = 'ADD_VISIT'
export const UPDATE_VISIT = 'UPDATE_VISIT'
export const SAVING_VISIT = 'SAVING_VISIT';
export const SAVING_VISIT_CANCEL = 'SAVING_VISIT_CANCEL';
export const VISITS_LOADING = 'VISITS_LOADING';
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN = "LOGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_CANCEL = "LOGIN_CANCEL"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS"
export const RESET_PASSWORD_REQUEST_CONTINUE = "RESET_PASSWORD_REQUEST_CONTINUE"
export const RESET_PASSWORD_REQUEST_FAIL = "RESET_PASSWORD_REQUEST_FAIL"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_CONTINUE = "RESET_PASSWORD_CONTINUE"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"
export const SAVING_AUTHUSER = "SAVING_AUTHUSER"
export const UPDATE_AUTHUSER_SUCCESS = "UPDATE_AUTHUSER_SUCCESS"
export const UPDATE_AUTHUSER_FAIL = "UPDATE_AUTHUSER_FAIL"
export const GET_NOTICES = 'GET_NOTICES';
export const CLEAR_NOTICES = 'CLEAR_NOTICES';
